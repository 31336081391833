import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "./Styles.css";

const Politica = () => {
  return (
    <div>
      <Header />
      <div className="content-politica">
        <h1>POLÍTICA DE PRIVACIDADE</h1>
        {/* <h2>SEÇÃO 1 – O QUE FAREMOS COM ESTA INFORMAÇÃO?</h2> */}
        <p>
          Bem-vindo ao nosso website. O Cemitério Parque Campo da Paz oferece
          produtos e serviços, cemiteriais e de cremação,. Assim, as referências
          aplicadas nesta política de privacidade se aplicam ao website,
          formulários, criados pelo Cemitério Parque Campo da Paz.
        </p>
        <p>
          Os dados pessoais coletados serão utilizados para melhorar sua
          experiência com nossos produtos e serviços. No caso de envio de Email.
        </p>
        <p>
          Em razão disto, nossa política de privacidade está desenhada para
          ajudá-lo a conhecer as boas práticas adotadas pelo Cemitério Parque
          Campo da Paz para preservar sua privacidade e segurança.
        </p>
        <p>
          Ao acessar o Cemitério Parque Campo da Paz através do website você
          deve conhecer. Primeiramente, que nossa rede de contato é definida
          pelo domínio do e-mail de uma de nossas empresas. O endereço de e-mail
          de quem estiver correspondendo deve corresponder ao domínio do e-mail
          de nossa empresa e somente pessoas autorizadas pertencentes ao domínio
          podem visualizar as informações geradas. As ferramentas
          administrativas do Cemitério Parque Campo da Paz não permitem que
          nossos colaboradores visualizem informações caso não tenha autorização
          de acesso a rede ou plataforma de informação.
        </p>
        <p>
          Esta Política de Privacidade pode ser alterada a qualquer momento à
          medida das experiências com os usuários. Consulte frequentemente este
          canal para obter as atualizações da nossa Política de Privacidade.
        </p>
        <br></br>
        <h2>Informações sobre Cookies</h2>

        <p>
          O Cemitério Parque Campo da Paz poderá empregar o uso de “cookies” em
          seus website ou relacionado a ele para melhorar a informações sobre os
          visitantes. Um cookie é um pequeno arquivo de texto que pode incluir
          um identificador de preferências. O Cemitério Parque Campo da Paz pode
          utilizar cookies de “sessão” e cookies “persistentes”. Cookies
          persistentes podem ser utilizadas em suas visitas posteriores ao
          website, mas podem ser removidas sempre que você quiser utilizando as
          orientações de seu navegador da Web. Já o cookie de sessão tem caráter
          temporário e desaparece após você fechar o navegador. Assim, ao
          visitar nosso website, será solicitada a permissão a seu computador
          para empregar o uso de um cookie de sessão ou persistente. Nosso
          website então enviará um cookie para seu navegador, se suas
          preferências de navegação permitirem. A maioria dos navegadores podem
          ser organizados para informar sobre o envio de um cookie de tal forma
          que você possa aceitá-lo ou não. Todavia, vale lembrar que, em alguns
          casos, recusar o uso de um cookie poderá ter um impacto negativo na
          forma de exibição do nosso website ou em algumas de suas
          funcionalidades.
        </p>

        <h2>Informações e Uso de Dados Pessoais </h2>
        <p>
          O Cemitério Parque Campo da Paz utilizará suas Informações pessoais
          para responder suas solicitações. Podemos combinar as informações que
          coletamos através de diferentes meios ou em momentos diferentes,
          inclusive Informações Pessoais e Informações sobre Uso do Website, e
          usaremos tais informações junto com as que foram obtidas de outras
          fontes, inclusive (sem limitação) qualquer um de nossos outros
          websites e terceiros. Além disso, podemos analisar o comportamento dos
          usuários como uma medida do interesse e uso de nosso Website de forma
          individual ou coletivamente.
        </p>
        <p>
          O Cemitério Parque Campo da Paz não compartilhará, alugará ou venderá
          suas Informações Pessoais a outras partes.{" "}
        </p>
        <h2>Compartilhando e Atuação de Terceiros </h2>
        <p>
          O Cemitério Parque Campo da Paz poderá utilizar-se de parceiros
          terceirizados para ajudar a operar e manter nosso Website ou em alguma
          etapa do processo de contratação dos nossos produtos e serviços. Para
          tanto, poderemos compartilhar suas Informações pessoais e Informações
          advindas do Website com prestadores de serviços e outros terceiros
          (“Partes Afiliadas”) que prestam serviço ou oferecem produtos na
          cadeia de nosso Website ou através dele ou para nosso negócio
          (hospedagem de websites, banco de dados, prestadores de serviços de
          comunicação, serviços de e-mail, empresas de assinatura digital,
          distribuição e logística, processamento de cartões de crédito e outros
          prestadores de serviços semelhantes que utilizam tais informações de
          forma relevante). Lembramos que os prestadores de serviços
          terceirizados estão contratualmente proibidos de utilizar ou divulgar
          as Informações Pessoais e Informações sobre Uso do Website, exceto
          conforme necessário para realizar os serviços demandados do Cemitério
          Parque Campo da Paz ou para cumprir requisitos legais, sempre sujeitos
          aos termos desta Política de Privacidade.
        </p>
        <h2>Divulgação Obrigatória por Lei</h2>
        <p>
          O Cemitério Parque Campo da Paz poderá divulgar Informações pessoais
          nos casos exigidos por lei federal, estadual ou municipal, para
          cumprir ordem ou decisão judicial ou para proteger e defender os
          interesses dos usuários, filiadas devidamente fundamentados.
        </p>
        <h2>
          Consentimento para Transferência e Processamento de Dados Pessoais
        </h2>
        <p>
          Ao fornecer as Informações Pessoais através do Website, você concorda
          que elas podem ser utilizadas pelo Cemitério Parque Campo da Paz para
          os fins descritos neste documento e compreende e consente ainda com a
          coleta, manutenção, processamento e transferência de tais informações.
          Ao fornecer suas Informações Pessoais pelos canais aqui mencionados,
          você consente com o processamento de suas informações em qualquer
          jurisdição, de acordo com esta Política de Privacidade.
        </p>
        <h2>Acesso e Direito a Correção de Dados</h2>
        <p>
          Você tem o direito de se recusar a submeter Informações Pessoais
          através dos canais mencionados nesta política de privacidade, situação
          em que o Cemitério Parque Campo da Paz não poderá prestar determinados
          serviços ou oferecer produtos. Entretanto, caso escolha fornecer
          Informações Pessoais ao Cemitério Parque Campo da Paz , serão
          fornecidos meios de acesso, correção de qualquer inexatidão, inclusão,
          atualização ou exclusão das suas Informações Pessoais, enviando um
          e-mail para cemiteriocampodapaz@hotmail.com. As solicitações por este
          canal terão prazo de 30 dias para resposta.
        </p>
        <h2>Proteções e Seguranças</h2>
        <p>
          O Cemitério Parque Campo da Paz utiliza proteções físicas,
          administrativas e técnicas comercialmente razoáveis em seus servidores
          para preservar a integridade e segurança de suas Informações Pessoais.
          Também utiliza a criptografia para transmitir informações
          confidenciais. Embora o Cemitério Parque Campo da Paz se esforce para
          manter a segurança e integridade de Informações Pessoais delicadas
          fornecidas por meio de seu Website, o usuário deve estar ciente que o
          Cemitério Parque Campo da Paz não se responsabiliza pelos resultados
          decorrentes da vulnerabilidade durante a transmissão através da
          Internet ou de armazenamentos decorrente da intrusão não autorizada,
          como, por exemplo, hackers.
        </p>
        <p>
          Na hipótese remota de comprometimento da segurança de suas Informações
          Pessoais sob nossa posse ou controle, O Cemitério Parque Campo da Paz
          notificará os usuários sobre tal fato utilizando-se dos endereços e
          meios disponíveis, ficando autorizado de forma expressa o uso do
          e-mail como meio para tal notificação. Caso o usuário prefira a
          utilização de outro método de notificação da suposta situação, deve
          ser enviada uma mensagem com as informações de contato alternativas
          que pretende ser utilizada.
        </p>
        <h2>Atualização da Política de Privacidade </h2>
        <p>
          Nossa Política de Privacidade poderá ser atualizada periodicamente e
          sem prévia comunicação, que passarão a vigorar a partir da data de sua
          publicação. Recomendamos que nosso website seja visitado
          periodicamente e consultado sobre as políticas de privacidade.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Politica;
