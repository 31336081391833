import React, { useEffect, useRef } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Logo from "../../images/logo-park 1.png";
import I1 from "../../images/iconsNew/estrutura.svg";
import I2 from "../../images/iconsNew/missas.svg";
import I3 from "../../images/iconsNew/missao.svg";

import "./Style.css";

const Home = () => {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);
  return (
    <div>
      <Header />
      <main className="main">
        <div>
          <h1>
            <img src={Logo} width="400" alt="Logo" srcset="" />
          </h1>
          <p className="bem-vindo">Bem vindo</p>
          <p className="p-main-one">
            Estamos aqui para você e toda sua família
          </p>
          <p className="p-main-two">Como podemos ajudar?</p>

          <div className="btn-home-main">
            {/* https://api.whatsapp.com/send?phone=5522998160181&text=Ol%C3%A1%2C%20Perdi%20uma%20pessoa%20e%20gostaria%20do%20auxilio%20de%20voc%C3%AAs%20para%20proceder%20com%20os%20demais%20processos. https://api.whatsapp.com/send?phone=5522998160181&text=Ol%C3%A1%2C%20Perdi%20uma%20pessoa%20e%20gostaria%20do%20auxilio%20de%20voc%C3%AAs%20para%20proceder%20com%20os%20demais%20processos. */}
            <button
              onClick={() => {
                window.location.href = "/perdialguem";
              }}
            >
              Perdi Alguem
            </button>

            <button
              onClick={() => {
                window.location.href =
                  "https://api.whatsapp.com/send?phone=5522997071781&text=Ol%C3%A1%2C%20Tudo%20bem%3F%20Vim%20pelo%20site%20de%20voc%C3%AAs.";
              }}
            >
              Fale conosco
            </button>
          </div>
        </div>
      </main>

      <section className="icons-main">
        <div onClick={() => (window.location.href = "/Estrutura")}>
          <img src={I1} alt="I1" />
          <p>Estrutura</p>
        </div>
        <div onClick={() => (window.location.href = "/Missa")}>
          <img src={I2} alt="I2" />
          <p>Missa</p>
        </div>
        <div onClick={() => (window.location.href = "/Missao")}>
          <img src={I3} alt="I3" />
          <p>Missão e Valores</p>
        </div>
      </section>

      <section className="text-main-text-home">
        <video
          style={{ maxWidth: "100%", width: "800px", margin: "0 auto" }}
          playsInline
          loop
          muted
          controls
          alt="All the devices"
          src="  https://cemiteriocampodapaz.com/video.mp4"
          ref={videoEl}
        />
        <p>
          Fundado em 1979, o Cemitério Parque Campo da Paz, vem realizando um
          trabalho com muito profissionalismo, agregando sempre o atendimento
          humanizado as famílias enlutadas, Recentemente, sempre pensando em
          melhor atender a comunidade Norte Fluminense, o Cemitério Parque Campo
          da Paz, finalizou as obras do crematório do empreendimento.
          Proporcionando a estrutura completa para atender toda região.
        </p>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
