import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Iframe from "react-iframe";
import "./Styles.css";

const Edereco = () => {
  return (
    <div className="main-back-img-fundo">
      <Header />
      <div className="div-endereco-maps">
        <h2>Onde Estamos:</h2>
        <ul>
          <li>Estr. do Capão, 773</li>
          <li>Parque Joao Maria, Campos dos Goytacazes - RJ, 28026-495</li>
        </ul>
        <Iframe
          url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3704.744742268307!2d-41.32788468558882!3d-21.79013800445506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xbdd6857638011b%3A0x85ab1773dc038988!2sCemit%C3%A9rio%20Campo%20da%20Paz!5e0!3m2!1spt-BR!2sbr!4v1668002051308!5m2!1spt-BR!2sbr"
          width="100%"
          height="400px"
          id="iframe-maps-campo-da-paz"
          className=""
          display="block"
          position="relative"
        />{" "}
      </div>
      <Footer />
    </div>
  );
};

export default Edereco;
