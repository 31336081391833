import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import EstruturaGerarl from "./estrutura.jpg";
import EstruturaGerarl2 from "./estrutura2.jpg";
import EstruturaGerarl3 from "./estrutura3.jpg";
import EstruturaGerarl4 from "./estrutura4.jpg";

import EstruturaImg from "../../images/iconsNew/estrutura.svg";
import "./Styles.css";

const Estrutura = () => {
  return (
    <div className="main-back-img-fundo">
      <Header />
      <div className="Missas">
        <img src={EstruturaImg} width="150" alt="" srcset="" />
        <p>
          Em nossa estrutura temos: 7 Capelas, Sala da família, capela
          ecumênica, floricultura, cantina, ossário, columbário, salão
          cerimonial, salão cerimonial, estacionamento gratuito. Tudo isso em
          perfeita harmonia com a natureza local proporcionando aos visitantes
          um ambiente tranquilo e aconchegante.
        </p>
      </div>
      <div className="div-ajust-flex-geral">
        <img src={EstruturaGerarl} alt="estrutura" srcset="" />
        {/* <img src={EstruturaGerarl2} alt="estrutura" srcset="" /> */}
        <img src={EstruturaGerarl3} alt="estrutura" srcset="" />
        <img src={EstruturaGerarl4} alt="estrutura" srcset="" />
      </div>

      <Footer />
    </div>
  );
};

export default Estrutura;
