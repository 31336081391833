import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import MissasImg from "../../images/iconsNew/missas.svg";
import missa1 from "./missa1.jpg";
import missa2 from "./missa2.jpg";

import "./Styles.css";

const Missas = () => {
  return (
    <div className="main-back-img-fundo">
      <Header />
      <div className="Missas">
        <img src={MissasImg} width="150" alt="" srcset="" />
        <p>
          Temos 1 vez por mês a missa em homenagem aos falecidos. A missa é
          sempre realizada em nossa capela ecumênica. Entre em contato conosco e
          dê o nome do seu ente querido e venha participar dessa homenagem.
        </p>
      </div>
      <div className="missa-flex-column">
        <img src={missa1} alt="" srcset="" />
        <img src={missa2} alt="" srcset="" />
      </div>
      <Footer />
    </div>
  );
};

export default Missas;
