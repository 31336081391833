import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import MissaoImg from "../../images/iconsNew/missao.svg";
import MissaoGeral from "./missao.jpg";
import "./Styles.css";

const Missao = () => {
  return (
    <div className="main-back-img-fundo">
      <Header />
      <div className="missao">
        <div className="missao-icon">
          <img src={MissaoImg} width="150" alt="MissaoImg" srcset="" />
        </div>
        <div className="missao-valores">
          <p>
            <span>Missão:</span>
            Nossa razão de ser é prestar um serviço humanizado com dignidade as
            famílias enlutadas, contribuindo para perpetuar suas histórias.
          </p>
          <p>
            <span>Valores:</span>
            Comprometimento, humildade, respeito, união e simplicidade,
            segurança, conforto, dignidade e muita paz.
          </p>

          <p id="text-valores-missao-grande">
            Fundado em 1979, o Cemitério Parque Campo da Paz, vem realizando um
            trabalho com muito profissionalismo, agregando sempre o atendimento
            humanizado as famílias enlutadas, Recentemente, sempre pensando em
            melhor atender a comunidade Norte Fluminense, o Cemitério Parque
            Campo da Paz, finalizou as obras do crematório do empreendimento.
            Proporcionando a estrutura completa para atender toda região.
          </p>
        </div>
      </div>
      <div className="missao-geral-flex">
        <img src={MissaoGeral} alt="" srcset="" />
      </div>
      <Footer />
    </div>
  );
};

export default Missao;
