import { Carousel } from "react-carousel-minimal";
import React, { useEffect, useState } from "react";

function App() {
  const [mobile, setMobile] = useState("100%");
  useEffect(() => {
    let wid = window.screen.width;
    if (wid <= 600) {
      setMobile("300px");
    } else {
      setMobile("100%");
    }
  }, []);
  const data = [
    {
      image:
        "https://cemiteriocampodapaz.com/imagens/floricultura/ZFC_1315.jpg",
      caption: "Floricultura",
    },
    {
      image:
        "https://cemiteriocampodapaz.com/imagens/sala%20de%20espera/ZFC_1356.jpg",
      caption: "Columbário",
    },

    {
      image: "https://cemiteriocampodapaz.com/imagens/velorios/ZFC_1658.jpg",
      caption: "Cerimonial",
    },
    {
      image: "https://cemiteriocampodapaz.com/imagens/velorios/petalas.jpeg",
      caption: "Cerimonial",
    },
    // {
    //   image:
    //     "https://cemiteriocampodapaz.com/imagens/lanchonete/ZFC_1321%20(1).jpg",
    //   caption: "Lanchonete",
    // },
    {
      image: "https://cemiteriocampodapaz.com/imagens/lapides/ZFC_1374.jpg",
      caption: "Lapides",
    },
    {
      image:
        "https://cemiteriocampodapaz.com/imagens/igreja/ZFC_1387%20(2).jpg",
      caption: "Templo Ecumênico",
    },

    {
      image: "https://cemiteriocampodapaz.com/imagens/crematorio/ZFC_1396.jpg",
      caption: "Crematório",
    },
    {
      image:
        "https://cemiteriocampodapaz.com/imagens/sala%20de%20espera/ZFC_1647.jpg",
      caption: "Capela",
    },
    {
      image:
        "https://cemiteriocampodapaz.com/imagens/crematorio/ZFC_1665%20(1).jpg",
      caption: "Crematório",
    },
  ];

  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };
  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };

  return (
    <div className="App">
      <div style={{ textAlign: "center" }}>
        <div
          style={{
            padding: "0 20px",
          }}
        >
          <Carousel
            data={data}
            time={5000}
            width={mobile}
            captionStyle={captionStyle}
            radius="10px"
            slideNumber={true}
            slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={true}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
            thumbnails={true}
            thumbnailWidth="100px"
            style={{
              textAlign: "center",
              maxWidth: "850px",
              maxHeight: "500px",
              margin: "40px auto",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
