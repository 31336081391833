import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ImageCheck from "../../images/circulo.svg";
import Form from "../../components/Form";
import Container from "../../components/Container";
import "./Styles.css";
const AreaCliente = () => {
  const triggerText = "Open form";
  const onSubmit = (event) => {
    event.preventDefault(event);
    console.log(event.target.name.value);
    console.log(event.target.email.value);
  };
  const [open, setOpen] = useState(false);
  function openForm(abrir) {
    setOpen(abrir);
  }

  return (
    <div className="main-back-img-fundo">
      <Header />
      <main className="main-area-cliente">
        <div className="area-img-cliente">
          <Form></Form>
        </div>
        <div className="area-text-cliente">
          <h2>Atualize seu cadastro</h2>
          <p>Porque devo atualizar meu cadastro?</p>
          <p>
            É muito importante manter o seu cadastro atualizado com, endereço,
            telefone e e-mails atuais. Para atualizar o cadastro basta nos
            enviar mensagem pelo WhatsApp, E-mail ou Formulário presente na página.
            Atualização do cadastro deve ser feita pelo titular do cadastro ou
            ser representante legal. Mantenha sua taxa de manutenção em dia para
            evitar inadimplência e viabilizar as manutenções necessárias no
            cemitério.
          </p>
          <br />

          {/* 
          <button id="atualizar-agora" onClick={() => openForm(true)}>
            Entre em contato
          </button> */}
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default AreaCliente;
