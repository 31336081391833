import React from "react";
import Btn from "./btn.png";
import "./style.css";

const ButtonWpp = () => {
  return (
    <div>
      <button
        id="button-wpp"
        onClick={() => {
          window.location.href =
            "https://api.whatsapp.com/send?phone=5522997071781&text=Ol%C3%A1,%20tenho%20uma%20d%C3%BAvida%20voc%C3%AAs%20podem%20me%20ajudar?";
        }}
      >
        <img src={Btn} alt="" width={65} srcset="" />
      </button>
    </div>
  );
};

export default ButtonWpp;
