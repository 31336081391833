import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Local from "../../images/iconsNew/local_library_FILL0_wght400_GRAD0_opsz48.svg";
import "./Styles.css";

const Perdi = () => {
  return (
    <div className="main-back-img-fundo">
      <Header />
      <div className="main-lost">
        <div className="main-lost-second">
          <h1>Procedimento em caso de Falecimento:</h1>
          <h2>- Ligar para funerária</h2>
          <h2>- Ligar para o cemitério</h2>
          <h2>- Obter a certidão de óbito:</h2>
          <h3>
            1 – Declaração de óbito: Um médico irá fornecer esse documento, logo
            após o falecimento no hospital ou IML.
          </h3>
          <h3>
            2 – Após ter em mãos a declaração de óbito, ir a um cartório
            (Registro natural mais próximo a residência ou local do
            falecimento), para emissão de óbito com seguintes documentos
            original e cópia:
          </h3>
          <br></br>
          <ul>
            <li>
              Declaração de óbito (fornecida pelo hospital ou IML) documento
              original;
            </li>
            <li>Certidão de nascimento ou casamento do obituado;</li>
            <li>
              Caso o obituado seja viúvo, trazer certidão de óbito do cônjuge
              falecido;
            </li>
            <li>
              Caso o obituado seja divorciado ou separado judicialmente, trazer
              a certidão com a respectiva averbação;
            </li>
            <li>
              Documentos pessoais do obituado (RG, CPF, CTPS, Titulo Eleitoral);
            </li>
            <li>
              Cartão de beneficio do INSS (Caso o cartão seja de conta corrente,
              trazer um documento do INSS que tenha o número do beneficio);
            </li>
            <li>
              O declarante deve ser maior de 18 anos, da família do obituado e
              portador de RG e CPF;
            </li>
          </ul>
        </div>
        <div className="box-container-box-cartorio">
          <div className="box-cartorio">
            <img src={Local} width={50} height={50} alt="" srcset="" />
            <div>
              <p>1º Subdistrito de 1º Distrito de Campos dos Goytacazes – RJ</p>
              <p>
                {" "}
                Rua Salvador Correa, 102, Centro, Tel.: 22 2726-9176 ou 22
                99909-9176
              </p>
            </div>
          </div>
          <div className="box-cartorio">
            <img src={Local} width={50} height={50} alt="" srcset="" />
            <div>
              <p>2º Subdistrito de 1º Distrito de Campos dos Goytacazes – RJ</p>
              <p>Rua Ipiranga, 35, Centro, Tel.: 22 2724-3478</p>
            </div>
          </div>
          <div className="box-cartorio">
            <img src={Local} width={50} height={50} alt="" srcset="" />
            <div>
              <p>3º Subdistrito de 1º Distrito de Campos dos Goytacazes – RJ</p>
              <p>
                {" "}
                Rua João Alberto, 09, Jardim Carioca, Tel: 22 2733-1632 ou 22
                99212-8112
              </p>
            </div>
          </div>
        </div>
        <div className="fale">
          <button
            id="fale-conosco"
            onClick={() =>
              (window.location.href =
                "https://api.whatsapp.com/send?phone=5522998160181&text=Ol%C3%A1%2C%20Perdi%20uma%20pessoa%20e%20gostaria%20do%20auxilio%20de%20voc%C3%AAs%20para%20proceder%20com%20os%20demais%20processos. ")
            }
          >
            Fale conosco
          </button>
          <button
            id="fale-conosco"
            onClick={() =>
              (window.location.href =
                "https://api.whatsapp.com/send?phone=5522997071781&text=Ol%C3%A1%2C%20Tudo%20bem%3F%20Vim%20pelo%20site%20de%20voc%C3%AAs.")
            }
          >
            Fale conosco (2)
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Perdi;
