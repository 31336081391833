import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Carousel } from "react-carousel-minimal";
import ImageCrm from "./ZFC_1355.jpg";
import "./style.css";
const Crematorio = () => {
  const [mobile, setMobile] = useState("100%");
  useEffect(() => {
    let wid = window.screen.width;
    if (wid <= 600) {
      setMobile("300px");
    } else {
      setMobile("100%");
    }
  }, []);
  const data = [
    {
      image: "https://cemiteriocampodapaz.com/imagens/igreja/one.jpg",
      //   caption: "Floricultura",
    },
    {
      image: "https://cemiteriocampodapaz.com/imagens/crematorio/one.jpg",
      //   caption: "Floricultura",
    },
   
    {
      image: "https://cemiteriocampodapaz.com/imagens/crematorio/two.jpg",
      //   caption: "Sala de Espera",
    },

    {
      image: "https://cemiteriocampodapaz.com/imagens/crematorio/three.jpg",
      //   caption: "Velório",
    },
    // {
    //   image:
    //     "https://cemiteriocampodapaz.com/imagens/lanchonete/ZFC_1321%20(1).jpg",
    //   caption: "Lanchonete",
    // },
    {
      image: "https://cemiteriocampodapaz.com/imagens/crematorio/four.jpg",
      //   caption: "Lapides",
    },
    {
      image: "https://cemiteriocampodapaz.com/imagens/velorios/cbm.jpg"
    },
    {
      image: "https://cemiteriocampodapaz.com/imagens/crematorio/five.jpg",
      //   caption: "Missa",
    },

    {
      image: "https://cemiteriocampodapaz.com/imagens/crematorio/seven.jpg",
      //   caption: "Crematório",
    },
    {
      image: "https://cemiteriocampodapaz.com/imagens/crematorio/eigth.jpg",
      //   caption: "Sala de Espera",
    },
    {
      image: "https://cemiteriocampodapaz.com/imagens/crematorio/nine.jpg",
      //   caption: "Crematório",
    },
    {
      image: "https://cemiteriocampodapaz.com/imagens/crematorio/teen.jpg",
      //   caption: "Crematório",
    },
    {
      image: "https://cemiteriocampodapaz.com/imagens/crematorio/end.jpg",
      //   caption: "Crematório",
    },
  ];

  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };
  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };

  return (
    <div className="main-back-img-fundo" id="crematorio-crm">
      <Header />
      <br />
      <br />
      <br />
      <br />
      <div>
        {" "}
        <Carousel
          data={data}
          time={3000}
          width={mobile}
          captionStyle={captionStyle}
          radius="10px"
          slideNumber={true}
          slideNumberStyle={slideNumberStyle}
          captionPosition="bottom"
          automatic={true}
          dots={true}
          pauseIconColor="white"
          pauseIconSize="40px"
          slideBackgroundColor="darkgrey"
          slideImageFit="cover"
          thumbnails={true}
          thumbnailWidth="100px"
          style={{
            textAlign: "center",
            maxWidth: "850px",
            maxHeight: "500px",
            margin: "40px auto",
          }}
        />
      </div>
      <div id="crm-conter">
        <h1>Crematório Campo da Paz</h1>

        <p>
          <strong>Cremação:</strong>É uma maneira de despedida, cada vez mais procurada por familiares. O Crematório Cemitério Parque Campo da Paz, oferece um ambiente muito especial, com estrutura completa para homenagem, cerimoniais, velórios e acolhimento a familiares e amigos.
        </p>

        <p>
          <strong>O que é um crematório?</strong> É onde ocorre a cremação. O processo que transforma o corpo em cinzas. O forno crematório tem alta tecnologia proporcionando segurança ao processo, cada corpo passa pelo processo individualmente.
        </p>

        <p>
          <strong>Porque optar pelo Crematório Campo da paz? </strong> Somos o
          primeiro crematório em Campos dos Goytacazes, contando com vasta
          experiência no processo de soluções cemiteriais. Seguimos todos os
          protocolos de segurança e legislação com total transparência para a
          família. Além disso, nossas modernas instalações permitem homenagem
          diversas no momento da despedida.
        </p>

        <p>
          <strong>Onde as cinzas são guardadas? </strong> Esta é uma opção da
          família. É possível mantê-las seguras em um local apropriado, com
          espaço para pequenas homenagens e visitas confortáveis. A família que
          optar por espargir as cinzas em algum lugar especial. Há diversos
          tipos de urnas para atender melhor os desejos de cada um.
        </p>

        <p>
          <strong>Cerimonial:</strong> No Crematório Campo da Paz, temos um
          excelente espaço para a família realizar uma cerimônia com música
          ambiente, projeção de fotos e espaço para familiares e amigos dizerem
          palavras de despedida. Oferecemos também um pequeno momento especial
          para a entrega das cinzas.
        </p>

        <p>
          <strong>Columbário:</strong> Quando o corpo de uma pessoa querida é
          cremado, seus familiares recebem as cinzas que podem ser entregues em
          diferentes modelos de urnas. Essas urnas podem ser colocadas em nosso
          columbário onde os familiares poderão ficar visitando-as de forma bem
          confortável e segura.
          {/* <img src={ImageCrm} alt="" srcset="" /> */}
        </p>
      </div>

      <Footer />
    </div>
  );
};

export default Crematorio;
