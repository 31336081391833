import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import "./Styles.css";

export const Form = ({ onSubmit }, props) => {
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");

  function hiddenTest() {
    props.funcao = (parametro) => {
      parametro = false;
    };
  }
  function sendEmail(e) {
    e.preventDefault();
    const templateParams = {
      from_name: user,
      message: text,
      email: email,
    };
    emailjs
      .send(
        "service_0bpd5hd",
        "template_7k4y9no",
        templateParams,
        "c-jprlpvXzGF0m5Ve"
      )
      .then(
        (response) => {
          console.log("Email enviado", response.status, response.text);
          setEmail("");
          setUser("");
          setText("");
        },
        (err) => {
          console.log("error", err);
        }
      );
  }
  return (
    <form onSubmit={sendEmail} onClick={hiddenTest}>
      <div className="form-group">
        <label htmlFor="name">Name: </label>
        <input
          placeholder="nome do cliente"
          className="form-control"
          id="name"
          value={user}
          onChange={(e) => setUser(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="email">Email: </label>
        <input
          type="email"
          className="form-control"
          id="email"
          placeholder="name@example.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className="form-group">
        <label htmlFor="message">Menssagem: </label>
        <textarea
          type="message"
          className="form-control"
          id="message"
          placeholder="Digite algo..."
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </div>
      <div className="form-group">
        <button
          className="form-control btn btn-primary"
          id="submit-form"
          type="submit"
        >
          Submit
        </button>
      </div>
    </form>
  );
};
export default Form;
