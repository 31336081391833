import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineCloseCircle } from "react-icons/ai";
import "./Styles.css";

const SideBar = (props) => {
  const closeTeste = false;
  return (
    <>
      <div className="side">
        <div className="x-close">
          <div onClick={props.close}>
            <AiOutlineCloseCircle size={20} color="white" />
          </div>
        </div>
        <Link to="/">O Cemitério</Link>
        <Link to="/Capelas">Capelas</Link>
        <Link to="/Servicos">Serviços</Link>
        <Link to="/Endereco">Endereço</Link>

        <div className="header-box-area">
          <Link to="/AreaCliente">Área do cliente</Link>
        </div>
      </div>
    </>
  );
};

export default SideBar;
