import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Three from "../../components/Three";
import "./Styles.css";
const Capelas = () => {
  return (
    <div className="main-back-img-fundo">
      <Header />
      <br />

      <div className="main-servicos">
        <Three />
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default Capelas;
