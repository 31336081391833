import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import OtherServices from "../../components/OtherServices";
import Translado from "../../images/Graphic Elements.png";
import Sepultamento from "../../images/Vector.png";
import Exumacao from "../../images/exumacao 1.png";
import Lapide from "../../images/iconesnovo/lapide.svg";
import Ecumenico from "../../images/iconesnovo/ecumenico.svg";
import ossario from "../../images/iconesnovo/ossario.svg";
import Wpp from "../../images/button/wpp.png";
import Wifi from "../../images/IconsEstrutura/Wifi.png";
import Cap from "../../images/IconsEstrutura/home.png";
import flo from "../../images/IconsEstrutura/wind.png";
import Sea from "../../images/IconsEstrutura/search.png";
import Est from "../../images/IconsEstrutura/x-circle.png";
import SelectImage from "../../components/SelectImage";
import Caixao from "./cx.png";

import "./Styles.css";

const Servicos = () => {
  return (
    <div className="main-back-img-fundo">
      <Header />
  
      <div className="main-select-image">
      <h1 style={{ color: "#397639"}}>Serviços</h1>
        <SelectImage />
      </div>
      <div className="main-servicos-main">
        <div className="other-services-center">
          <OtherServices
            image={Translado}
            width={80}
            text="Translado"
            paragraf="O translado pode ser interno ou externo. Será interno quando os restos mortais do falecido é movido para outro jazigo. Será externo quando os restos mortais vierem de outro cemiterio."
          />
          <OtherServices
            width={50}
            image={Sepultamento}
            text="Sepultamento"
            paragraf="O Cemitério Campo da Paz, possui um projeto paisagístico que valoriza o verde. Todos os jazigos são sinalizados com uma lápide padrão com a identificação dos sepultados."
          />
          <OtherServices
            width={63}
            image={Caixao}
            text="Exumação"
            paragraf="Após 3 anos da data do sepultamento o corpo pode ser exumado para abrir espaço no jazigo. A exumação consiste na retirada dos restos mortais do falecido após o périodo mínimo de decomposição."
          />
          <OtherServices
            width={50}
            image={Ecumenico}
            text="Capela Ecumênica"
            paragraf="Capela ampla, arejada, com maior capacidade de ocupação de pessoas, onde podem ser realizadas homenagens antes do sepultamento nessa capela também são realizadas missas mensais, em homenagem aos falecidos nesse periodo."
          />
          <OtherServices
            width={50}
            image={Ecumenico}
            text="Cerimonial"
            paragraf="Em um ambiente sereno e acolhedor, o cerimonial realizado no cemitério é um momento de profundo significado. É uma ocasião em que nos reunimos para prestar homenagens aos entes queridos que partiram, honrando sua memória e celebrando a vida que viveram."
          />
          <OtherServices
            image={Lapide}
            width={53}
            text="Lapide"
            paragraf="
            As Lápides identificam as pessoas que estão sepultadas no jazigo. Contém o nome, data de nascimento, data de falecimento e localização do jazigo. Não é permitido outro tipo de inscrição na lápide."
          />
          <OtherServices
            width={50}
            image={ossario}
            text="Ossário"
            paragraf="Lugar reservado para colocar caixas de exumação de translados externos e internos. Para maiores informações, precos, regras, entrar em contato com a administração. Telefones no site. Todas as caixas são identificas. "
          />
          <OtherServices
            width={50}
            image={ossario}
            text="Cremação"
            paragraf="O processo que transforma o corpo em cinzas. O forno crematório tem alta tecnologia proporcionando segurança ao processo, cada corpo passa pelo processo individualmente."
          />
          <OtherServices
            width={50}
            image={ossario}
            text="Columbário"
            paragraf="Quando o corpo de uma pessoa querida é cremado, seus familiares recebem as cinzas que podem ser entregues em diferentes modelos de urnas. Essas urnas podem ser colocadas em nosso columbário onde os familiares poderão ficar visitando-as de forma bem confortável e segura."
          />


<OtherServices
            width={50}
            image={Sepultamento}
            text="Jazigo"
            paragraf="Localizados em nosso lindo jardim, os jazigos tem capacidade para duas urnas e seis exumações. deste modo proporcionamos a união da memória familiar."
          />

<OtherServices
            width={50}
            image={Sepultamento}
            text="Capelas Velório"
            paragraf="Capelas amplas com WIFI, salas privativas para família, com opções de monitores eletrônicos para exposição de fotos pra homenagear seus entes e banheiros privativos."
          />
        </div>
      </div>

      <div>
        <div className="h3-services">
          <h3>Floricultura & Funeraria</h3>
        </div>

        <div className="class-box-servicos-main-others">
          <div className="box-funeraria">
            <button
              id="btn-services-other"
              onClick={() => {
                window.location.href =
                  "https://api.whatsapp.com/send?phone=5522999899551&text=Ol%C3%A1%2C%20tudo%20bem%3F%20";
              }}
            >
              <div id="btn-services-other-green">
                {" "}
                <img src={Wpp} alt="" srcset="" />
              </div>
              <div id="btn-services-other-white">
                <p>Funerária</p>
              </div>
            </button>
          </div>
          {/* 
          <div className="box-cantina">
            <button
              id="btn-services-other"
              onClick={() => {
                window.location.href =
                  "https://api.whatsapp.com/send?phone=5522998757568&text=Ol%C3%A1%2C%20tudo%20bem%3F%20";
              }}
            >
              <div id="btn-services-other-green">
                {" "}
                <img src={Wpp} alt="" srcset="" />
              </div>
              <div id="btn-services-other-white">
                <p>Cardápio</p>
              </div>
            </button>
          </div> */}
          <div className="box-floricultura">
            {" "}
            <button
              id="btn-services-other"
              onClick={() => {
                window.location.href =
                  "https://api.whatsapp.com/send?phone=5522998757568&text=Ol%C3%A1%2C%20tudo%20bem%3F%20";
              }}
            >
              <div id="btn-services-other-green">
                {" "}
                <img src={Wpp} alt="" srcset="" />
              </div>
              <div id="btn-services-other-white">
                <p>Coroa de Flores</p>
              </div>
            </button>
          </div>
        </div>
        <div className="cemi-text-p">
          <p>
            Nosso cemitério tem uma cantina para os visitantes realizarem
            pequenos lanches com diversos salgados e bebidas. A Floricultura
            oferece uma excelente variedade de coroas e flores para ornamentação
            dos jazigos.
          </p>
        </div>
      </div>

      <div className="box-estrutura-services">
        <h2>Nossa Estrutura</h2>

        <div className="itens-estrutura">
          <div className="itens-estrutura-icon">
            <img src={Cap} alt="wifi" srcset="" />
            <p>Capelas</p>
          </div>
          <div className="itens-estrutura-icon">
            <img src={Cap} alt="wifi" srcset="" />
            <p>Templo Ecumenico</p>
          </div>
        </div>
        <div className="itens-estrutura">
          <div className="itens-estrutura-icon">
            <img src={flo} alt="wifi" srcset="" />
            <p>Floricultura</p>
          </div>
          <div className="itens-estrutura-icon">
            <img src={Est} alt="wifi" srcset="" />
            <p>Estacionamento Gratuito</p>
          </div>
        </div>

        <div className="itens-estrutura">
          <div className="itens-estrutura-icon">
            <img src={Wifi} alt="wifi" srcset="" />
            <p>Wi-Fi</p>
          </div>
          <div className="itens-estrutura-icon">
            <img src={Sea} alt="wifi" srcset="" />
            <p>Cantina</p>
          </div>
        </div>
      </div>
      <div className="div-taxa-de-manutencao">
        <h2>Taxa de Manutenção</h2>
        <div>
          <p>
            Todos os cemitérios privados possuem uma taxa de manutenção com
            cobrança semestral ou anual. Essa taxa refere-se a manutenção do
            emprendimento como um todo. Nesse item é importante averiguar o
            valor e o indice de reajuste.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Servicos;
