import React from "react";
import "./Styles.css";
const OtherServices = (props) => {
  return (
    <div className="box-services-others">
      <div>
        <img src={props.image} width={props.width} alt="icones" srcset="" />
      </div>
      <div className="box-services-text">
        <div className="text-height-p">
          <h3>{props.text}</h3>
        </div>
        <div className="height-box-other-text-main">
          <p>{props.paragraf}</p>
        </div>
      </div>
    </div>
  );
};

export default OtherServices;
