import React from "react";
import Logo from "../../images/logo-park 1.png";

import "./Styles.css";

const Footer = () => {
  return (
    <>
      <div className="Footer">
        <div className="Footer-text">
          <div>
            <img src={Logo} alt="Logo" width={200} srcset="" />
          </div>
          <div>
            <ul>
              <li>
                <a href="/Missao">Missão e Valores</a>{" "}
              </li>
              <li>
                <a href="/Politica">Politica de Privacidade</a>
              </li>
              <li>
                <a href="/Estrutura">Estrutura</a>{" "}
              </li>
              <li>Número Fixo: 22-27229011</li>
              <li>Número WhatsApp: 22-997071781</li>
              <li>Número WhatsApp: 22-998160181</li>
            </ul>
          </div>
          <div>
            <ul>
              <li>
                <a href="/Missao">Onde Estamos</a>{" "}
              </li>
              <li>
                <a href="/Politica">Fale Conosco</a>
              </li>
              <li>
                <a href="/Estrutura">Trabalhe Conosco</a>{" "}
              </li>
              <li>
                <a href="/Estrutura">Sobre Nós</a>{" "}
              </li>
              <li>
                <a href="/Estrutura">Política de Privacidade</a>{" "}
              </li>
            </ul>
          </div>
        </div>
        <div className="Footer-all">2022 © Todos os direitos reservados.</div>
      </div>
    </>
  );
};

export default Footer;
