import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./Pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AreaCliente from "./Pages/AreaCliente";
import Capelas from "./Pages/Capelas";
import Servicos from "./Pages/Servicos";
import Endereco from "./Pages/Endereco";
import Perdi from "./Pages/perdi";

import "./App.css";
import Estrutura from "./Pages/estrutura";
import Missa from "./Pages/missas";
import Missao from "./Pages/missao";
import Politica from "./Pages/politica";
import ButtonWpp from "./components/buttonWpp";
import Crematorio from "./Pages/Crematorio";
function App() {
  return (
    <div className="App">
      {" "}
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/AreaCliente" element={<AreaCliente />}></Route>
          <Route exact path="/Galeria" element={<Capelas />}></Route>
          <Route exact path="/Servicos" element={<Servicos />}></Route>
          <Route exact path="/Endereco" element={<Endereco />}></Route>
          <Route exact path="/perdialguem" element={<Perdi />}></Route>
          <Route exact path="/Estrutura" element={<Estrutura />}></Route>
          <Route exact path="/Missa" element={<Missa />}></Route>
          <Route exact path="/Missao" element={<Missao />}></Route>
          <Route exact path="/Politica" element={<Politica />}></Route>
          <Route exact path="/Crematorio" element={<Crematorio />}></Route>
        </Routes>
      </Router>
      <ButtonWpp />
    </div>
  );
}

export default App;
