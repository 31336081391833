import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import SideBar from "../SideBar";
import "./Styles.css";

const Header = () => {
  // script do scroll

  const [menuChange, setMenuChange] = useState(false);
  const [colorChange, setColorchange] = useState(false);
  const [colorMenu, Setcolormenu] = useState("black");
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
      Setcolormenu("white");
    } else {
      setColorchange(false);
      Setcolormenu("black");
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  // fim do script do scroll

  function sideChangeOpen() {
    setMenuChange(true);
  }
  function sideChangeClose() {
    setMenuChange(false);
  }
  return (
    <div
      className="header-main"
      id={colorChange ? "header-main-fixed" : "header-main"}
    >
      <div className="menu-hamburguer" onClick={sideChangeOpen}>
        <FiMenu size={20} color={colorMenu} />
      </div>
      <div>{menuChange == true ? <SideBar close={sideChangeClose} /> : ""}</div>

      <div className="header">
        <Link to="/">O Cemitério</Link>
        <Link to="/Galeria">Galeria</Link>
        <Link to="/Servicos">Serviços</Link>
        <Link to="/Crematorio">Crematorio</Link>
        <Link to="/Endereco">Endereço</Link>

        <div
          className="header-box"
          id={colorChange ? "area-client-header" : ""}
        >
          <div className="header-box-area">
            <Link to="/AreaCliente">Área do cliente</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
